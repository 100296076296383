import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import ProtectedReportGraphql from './protected-report.graphql';
import ReportDetailsTemplate from './reportDetailsTemplate';
import { NetworkStatus } from 'apollo-client';
import { parse } from 'flatted';

interface PageContext {
	pageContext: {
		id: string;
	};
}

interface QueryResult {
	contentfulReport: any;
}

const ProtectedReportDetails = (props: PageContext) => {
	const { id } = props.pageContext;
	const { data, loading, error, refetch, networkStatus } = useQuery<QueryResult>(
		ProtectedReportGraphql,
		{
			variables: {
				id,
			},
			notifyOnNetworkStatusChange: true,
		}
	);

	let report = undefined;
	if (data?.contentfulReport) {
		report = JSON.parse(JSON.stringify(data.contentfulReport));
		if (report.contents) {
			report.contents = {
				json: parse(report.contents.json), // parses circular references
			};
		}
	}
	return (
		<ReportDetailsTemplate
			pageContext={{
				report,
				loading: loading || networkStatus === NetworkStatus.refetch,
				error,
				refetch,
			}}
		/>
	);
};

export default class ProtectedReportDetailsComponent extends React.Component<PageContext> {
	render() {
		return <ProtectedReportDetails {...this.props} />;
	}
}
